<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="80px">
            <el-row>
              <el-col :md="8" :sm="12">
                <el-form-item label="主体名称">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.entityBaseInfoId"
                    @change="toChange"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in mainBodyList"
                      :key="item.entityId"
                      :label="item.entityName"
                      :value="item.entityId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="场馆名称">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.finalVenueId"
                    style="width: 100%"
                    @change="toSelect"
                  >
                    <el-option
                      v-for="item in venueList"
                      :key="item.id"
                      :label="item.venueName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="运动项目">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.sportsProjectCode"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in sportList"
                      :key="item.sportsProjectCode"
                      :label="item.sportsProjectName"
                      :value="item.sportsProjectCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="15">
                <el-form-item label="业务类型">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.orderType"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="18">
                <el-form-item label="下单时间">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="form.range"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="订单来源">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.orderFrom"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in orderList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="支付类型">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.payType"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in payTypeList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" class="text-right">
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 50"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
        :span-method="objectSpanMethod"
      >
        <el-table-column
          prop="entityBaseInfoName"
          align="center"
          label="主体名称"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="center"
          label="场馆名称"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          width="110"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="96"
          prop="typeDesc"
          label="业务"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="orderCount"
          min-width="120"
          label="订单数"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderAmount"
          min-width="140"
          label="订单总金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          min-width="100"
          prop="refundCount"
          show-overflow-tooltip
          label="退款订单量"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="refundAmount"
          min-width="180"
          label="退款总金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="income"
          width="120"
          label="总收入"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="incomePercent"
          width="120"
          label="收入占比"
        >
        </el-table-column>
        <el-table-column align="center" prop="status" label="操作">
          <template slot-scope="scope" v-if="scope.row.type != 'total'">
            <el-button @click="toDetail(scope.row)" type="text">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-wrapper">
      <div class=""></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import download from "@/mixins/download";
import UserSearchClass from "./userSearch";

export default {
  mixins: [mixin, download],
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      businessTypes: [],
      typeList: [
        {
          lable: "全部",
          value: "0",
        },
        {
          lable: "预约",
          value: "1",
        },
        {
          lable: "购票",
          value: "2",
        },
        {
          lable: "课程",
          value: "3",
        },
        {
          lable: "赛事",
          value: "4",
        },
        {
          lable: "会员卡",
          value: "6",
        },
      ],
      orderList: [
        {
          lable: "全部",
          value: "0",
        },
        {
          lable: "线上-小程序",
          value: "1",
        },
        {
          lable: "线下-现场开单",
          value: "2",
        },
      ],
      payTypeList: [
        {
          lable: "微信",
          value: "1",
        },
        {
          lable: "线下-现金",
          value: "2",
        },
        {
          lable: "线下-支付宝",
          value: "4",
        },
        {
          lable: "线下-微信",
          value: "5",
        },
        {
          lable: "线下-刷卡",
          value: "7",
        },
      ],
      mainBodyList: [],
      sportList: [],
      spanArr: [],
      spanArr1: [],
      spanArr2: [],
      venueList: [],
    };
  },
  computed: {
    ...mapState([
      "Language",
      "TOKEN",
      "venueId",
      "merchantAccountDetailInfoDTO",
    ]),
  },
  filters: {},
  activated() {
    if (this.merchantAccountDetailInfoDTO.merchantId) {
      this.findEntityByMerchantId(this.merchantAccountDetailInfoDTO.merchantId);
    }
  },
  mounted() {},
  methods: {
    toChange(val) {
      this.form.finalVenueId = "";
      this.$http
        .get(`${apis.getVenueListByEntityId}?entityId=${val}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.venueList = res.data.data;
          }
        });
    },
    findEntityByMerchantId(val) {
      this.$http
        .get(`${apis.findEntityByMerchantId}?merchantId=${val}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.mainBodyList = res.data.data;
          }
        });
    },
    toDetail(row) {
      if (this.form.range && this.form.range.length > 0) {
        this.$router.push({
          path: "platformDetail",
          query: {
            venueId: row.venueId,
            type: row.type,
            date: JSON.stringify(this.form.range),
          },
        });
      } else {
        this.$router.push({
          path: "platformDetail",
          query: {
            venueId: row.venueId,
            type: row.type,
          },
        });
      }
    },
    toSelect(value) {
      if (value) {
        this.getSportList(value);
      } else {
        this.form.sportsProjectCode = "";
        this.sportList = [];
      }
    },
    getSportList(id) {
      this.$http
        .get(`${apis.getSportsProjectListById}?id=${id}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    /**
     * @function table合并行
     */
    objectSpanMethod({
      // column,
      rowIndex,
      columnIndex,
    }) {
      if (columnIndex === 0) {
        if (this.spanArr1[rowIndex]) {
          return {
            rowspan: this.spanArr1[rowIndex],
            colspan: 1,
          };
        }
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
      if (columnIndex === 1) {
        if (this.spanArr2[rowIndex]) {
          return {
            rowspan: this.spanArr2[rowIndex],
            colspan: 1,
          };
        }
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
      if (columnIndex === 2) {
        if (this.spanArr[rowIndex]) {
          return {
            rowspan: this.spanArr[rowIndex],
            colspan: 1,
          };
        }
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.form.merchantAccountId = this.merchantAccountDetailInfoDTO.merchantId;
      const formData = {
        ...this.form,
        type: "1",
      };
      if (formData.range) {
        if (formData.range.length > 0) {
          formData.orderStTime = this.form.range[0];
          formData.orderEndTime = this.form.range[1];
        }
      } else {
        formData.orderStTime = "";
        formData.orderEndTime = "";
      }
      this.$http
        .post(apis.listIncome, {
          ...formData,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
            if (this.tableData.length > 0) {
              this.spanArr = [];
              let contactDot = 0;
              this.tableData.forEach((item, index) => {
                if (index === 0) {
                  this.spanArr.push(1);
                } else if (
                  item.sportsProjectName === this.tableData[index - 1].sportsProjectName && item.sortNumber === this.tableData[index - 1].sortNumber
                ) {
                  this.spanArr[contactDot] += 1;
                  this.spanArr.push(0);
                } else {
                  contactDot = index;
                  this.spanArr.push(1);
                }
              });
              this.spanArr1 = [];
              let contactDot1 = 0;
              this.tableData.forEach((item, index) => {
                if (index === 0) {
                  this.spanArr1.push(1);
                } else if (
                  item.entityBaseInfoName === this.tableData[index - 1].entityBaseInfoName
                ) {
                  this.spanArr1[contactDot1] += 1;
                  this.spanArr1.push(0);
                } else {
                  contactDot1 = index;
                  this.spanArr1.push(1);
                }
              });
              this.spanArr2 = [];
              let contactDot2 = 0;
              this.tableData.forEach((item, index) => {
                if (index === 0) {
                  this.spanArr2.push(1);
                } else if (item.venueId === this.tableData[index - 1].venueId) {
                  this.spanArr2[contactDot2] += 1;
                  this.spanArr2.push(0);
                } else {
                  contactDot2 = index;
                  this.spanArr2.push(1);
                }
              });
            }
          }
        });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 清空列表
     */
    cleanList() {
      this.$http.post(apis.operLog_clean).then((res) => {
        if (res.data.code === 0) {
          this.searchEvent();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.table-container {
  margin-top: 0;
}
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
</style>
